import Layout from 'components/comachicart/layout';
import Maintenance from 'components/comachicart/Maintenance';
import SEO from 'components/seo';
import React, {useEffect, useState} from 'react';

import {releace_endpoint_add_standard_plan} from '../../../constants';

const ReleacePage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(releace_endpoint_add_standard_plan);
      const jsonData = await response.json();
      setData(jsonData[0]);
      console.log(jsonData[0]);
    } catch (error) {
      console.log('データの取得中にエラーが発生しました:', error);
    }
  };

  return (
    <Layout>
      <SEO title='comachicart' path='comachicart/add-standard-plan' />
      {data ?  <Maintenance data={data} /> : <p>Loading...</p>}
    </Layout>
  );
};

export default ReleacePage;
